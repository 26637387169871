<template>
  <div class="notes-app">
    <h1>记录每个火花</h1>
    <el-form @submit.prevent="addNote" class="note-form">
      <el-form-item label="标题">
        <el-input v-model="newNote.title" placeholder="Enter title" required></el-input>
      </el-form-item>
      <el-form-item label="内容">
        <!-- <el-input
          type="textarea"
          :rows="4"
          v-model="newNote.content"
          placeholder="Enter content"
          required
        ></el-input> -->
        <v-md-editor v-model="newNote.content" height="400px"></v-md-editor>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">新增笔记</el-button>
        <el-button @click="clearForm" v-if="isEditing">清空内容及编辑状</el-button>
      </el-form-item>
    </el-form>
    <!-- 展示笔记的表格部分 -->
    <el-button icon="el-icon-refresh" @click="fetchNotes">刷新列表</el-button>

    <el-table
      :data="notes"
      style="width: 100%"
      v-loading="vLoad"
      element-loading-spinner="el-icon-loading"
      element-loading-text="数据加载中..."
    >
      <el-table-column prop="title" label="标题" width="200"></el-table-column>
      <el-table-column prop="content" label="内容" width="300">
        <template #default="scope">
          <div class="no-wrap">{{ scope.row.content }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template #default="scope">
          <el-button @click="deleteNote(scope.row.id)" type="danger" size="small"
            >删除</el-button
          >
          <el-button @click="eidtNote(scope.row)" type="primary" size="small"
            >编辑</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="创建者" width="150" prop="createdBy"> </el-table-column>
      <el-table-column label="创建时间" width="150" prop="createdAt"> </el-table-column>
      <el-table-column label="更新时间" width="150" prop="updatedAt"> </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { ref } from "vue";
import { get, post, del, put } from "@/util/request"; // 假设你的封装好的请求方法在 `utils/request.js` 中
import Swal from "sweetalert2";

export default {
  name: "App",
  setup() {
    const newNote = ref({ title: "", content: "", id: null });
    const notes = ref([]);
    // 添加一个状态变量来跟踪当前是新增还是编辑
    const isEditing = ref(false);
    const vLoad = ref(false);
    const fetchNotes = async () => {
      vLoad.value = true;
      console.log("开始刷新");
      const response = await get("/notes");
      vLoad.value = false;
      notes.value = response.data;
    };

    // 清空表单内容的方法
    const clearForm = () => {
      newNote.value = { title: "", content: "", id: null };
      isEditing.value = false; // 清空后将状态设置为新增
    };
    const addNote = async () => {
      if (newNote.value.id > 0) {
        if (!isEditing.value) {
          await Swal.fire({
            title: "提示",
            text: "当前不在编辑状态，请先选择编辑。",
            icon: "error",
            confirmButtonText: "确定",
          });
          return;
        }
        console.log("开始编辑");
        try {
          await put(`/notes/${newNote.value.id}`, newNote.value);
          // 清空表单并设置为新增状态
          clearForm();
          fetchNotes();
        } catch (error) {
          console.error(error);
        }
        // 编辑状态下的保存逻辑（可以通过发送PUT请求到后端等）
        // ...
      } else {
        if (!newNote.value.title) {
          await Swal.fire({
            title: "提示",
            text: "标题不能为空",
            icon: "error",
            confirmButtonText: "确定",
          });
          return false;
        }
        try {
          await post("/notes", newNote.value);
          newNote.value = { title: "", content: "" };
          // 清空表单并设置为新增状态
          clearForm();
          fetchNotes();
        } catch (error) {
          console.error(error);
        }
      }
    };

    const eidtNote = async (row) => {
      try {
        newNote.value.title = row.title;
        newNote.value.content = row.content;
        newNote.value.id = row.id;
        isEditing.value = true; // 设置为编辑状态

        console.log(newNote, "反显");
        // fetchNotes();
      } catch (error) {
        console.error(error);
      }
    };
    const deleteNote = async (id) => {
      try {
        await del(`/notes/${id}`);
        fetchNotes();
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotes();

    return {
      newNote,
      notes,
      addNote,
      deleteNote,
      eidtNote,
      isEditing,
      clearForm,
      vLoad,
      fetchNotes,
    };
  },
};
</script>

<style scoped>
.notes-app {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.note-form {
  margin-bottom: 20px;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 可选，如果文本太长，这将显示省略号 (...) */
}
.form-button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #45a049;
}

.notes-list {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.note-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #ccc;
}

.note-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.note-title {
  font-size: 18px;
  margin-bottom: 5px;
}

.delete-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #d32f2f;
}
/* 表格样式 */
.el-table {
  margin-top: 20px; /* 根据需要调整表格与上方内容的间距 */
}
</style>
