<template>
  <el-container style="height: 100vh">
    <el-header>参数配置页面</el-header>
    <div>
      <el-progress :percentage="progress" :stroke-width="10" striped></el-progress>
      <p>已抓取 {{ currentPage }} / {{ formData.totalPages }} 章</p>
    </div>
    <el-main>
      <el-form :model="formData" ref="formRef" label-width="120px">
        <el-form-item label="URL">
          <el-input v-model="formData.url"></el-input>
        </el-form-item>
        <el-form-item label="标题选择器">
          <el-input v-model="formData.titleSelector"></el-input>
        </el-form-item>
        <el-form-item label="书名设置">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item label="内容选择器">
          <el-input v-model="formData.contentSelector"></el-input>
        </el-form-item>
        <el-form-item label="总页数">
          <el-input-number v-model="formData.totalPages"></el-input-number>
        </el-form-item>
        <el-form-item label="保存目录">
          <el-input v-model="formData.directory"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import { ref, reactive, onMounted, onUnmounted } from "vue";
import axios from "axios";
import io from "socket.io-client";
// import { Socket } from 'socket.io-client';
// import { ElProgress } from "element-plus";

export default {
  name: "NoteCom",
  setup() {
    const scraping = ref(false);
    const currentPage = ref(0);
    const totalPages = ref(0);
    const progress = ref(0);
    let socket; // 将socket变量移动到setup作用域内，但不初始化

    const startScraping = async () => {
      try {
        scraping.value = true;
        socket = io("http://localhost:3000"); // 修改为服务端的地址和端口
        socket.on("chapterScraped", (data) => {
          console.log("抓取章节更新中", data);
          currentPage.value = data.currentPage;
          totalPages.value = data.totalPages;
          progress.value = ((currentPage.value / formData.totalPages) * 100).toFixed(1);
        });
        console.log("建立链接偶更", socket);

        socket.on("scrapingComplete", (data) => {
          scraping.value = false;
          console.log("抓取完成", data);
          // 在完成抓取后断开连接
          socket.disconnect();
        });

        socket.on("scrapingError", (error) => {
          scraping.value = false;
          console.error("抓取错误", error);
          // 在出现错误时断开连接
          socket.disconnect();
        });

        // 发送抓取请求到后端
        await socket.emit("startScraping", formData, (data) => {
          console.log(data); // { msg1: '测试1', msg2: '测试2' }
        });
      } catch (error) {
        console.error(error);
        alert("连接失败，请检查网络连接或服务器状态！");
      }
    };
    // const socket = inject('socket') as Socket;

    onMounted(() => {
      // let socket = io("http://localhost:3000/scrapeTxt", { debug: true }); // 修改为服务端的地址和端口
      // socket.connect(); //连接socket服务器
    });

    const formData = reactive({
      url: "https://www.bqg88.cc/xs/139248/",
      titleSelector: ".title",
      contentSelector: ".ReadAjax_content",
      totalPages: 3,
      directory: "C:\\Users\\Administrator\\Documents",
      title: "小说",
    });

    // const formData = reactive({
    //   url: "http://www.biqu5200.net/201_201052/",
    //   titleSelector: ".bookname h1",
    //   contentSelector: "#content",
    //   totalPages: 3,
    //   directory: "C:\\Users\\Administrator\\Documents",
    //   title: "小说",
    // });

    const formRef = ref(null);
    const submitForm = async () => {
      try {
        await startScraping(); // 在提交表单时开始抓取

        const response = await axios.post("/scrape", formData, {
          responseType: "blob", // 设置响应类型为 blob
        });
        socket.disconnect();

        // 创建一个 blob URL
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", formData.title + ".txt"); // 设置下载文件的名称
        document.body.appendChild(link);
        link.click(); // 模拟点击下载
      } catch (error) {
        console.error(error);
        alert("提交失败，请检查网络连接或服务器状态！");
      }
    };

    onUnmounted(() => {
      // 在组件卸载时断开WebSocket连接
      if (socket) {
        socket.disconnect();
      }
    });
    return { formData, formRef, submitForm, scraping, currentPage, totalPages, progress };
  },
};
</script>
<style scoped>
/* 整体样式 */
.el-container {
  display: flex;
  flex-direction: column;
  background-color: #f5f7fa; /* 淡淡的背景色 */
}

.el-header {
  background-color: #409eff; /* Element UI 主题色 */
  color: #ffffff; /* 字体颜色 */
  text-align: center;
  padding: 20px 0;
}

.el-main {
  flex: 1;
  padding: 20px;
}

/* 表单样式 */
.el-form {
  max-width: 600px; /* 限制表单的最大宽度 */
  margin: 0 auto; /* 水平居中 */
}

.el-form-item {
  margin-bottom: 20px; /* 表单项之间的间距 */
}

.el-button {
  width: 100%; /* 提交按钮宽度100% */
  padding: 10px 0; /* 提交按钮的内边距 */
}

/* 输入框和数字输入框的样式 */
.el-input,
.el-input-number {
  width: 100%; /* 输入框宽度100% */
}
</style>
