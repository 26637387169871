import axios from 'axios';

// 创建axios实例  
const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, // 设置基础URL，可以根据环境变量或配置文件进行设置  
    timeout: 50000, // 设置请求超时时间（毫秒）  
    withCredentials: true, // 跨域请求时是否携带凭据（如Cookies）  
});

// 请求拦截器  
service.interceptors.request.use(
    (config) => {
        // 在这里可以添加全局的请求配置，如设置请求头、添加身份验证信息等  
        // 例如：config.headers['Authorization'] = 'Bearer ' + token;  
        const token = localStorage.getItem('jwtToken');
        if (token) {
            config.headers['token'] = token; // 将 token 放在请求头的 token 字段中
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // 处理请求错误  
        console.error('请求错误：', error);
        return Promise.reject(error);
    }
);

// 响应拦截器  
service.interceptors.response.use(
    (response) => {
        // 对响应数据进行处理，如统一处理错误码、转换数据格式等  
        // 例如：if (response.data.code !== 0) { throw new Error(response.data.message); }  
        return response.data;
    },
    (error) => {
        // 处理响应错误  
        console.error('响应错误：', error);
        return Promise.reject(error);
    }
);

// 封装get请求方法  
export function get(url, params = {}) {
    return service.get(url, { params }).catch((error) => {
        throw error; // 重新抛出错误，以便在调用处进行捕获处理  
    });
}

// 封装post请求方法  
export function post(url, data = {}) {
    return service.post(url, data).catch((error) => {
        throw error; // 重新抛出错误，以便在调用处进行捕获处理  
    });
}

// 封装delete请求方法  
export function del(url, params = {}) {
    return service.delete(url, { params }).catch((error) => {
        throw error; // 重新抛出错误，以便在调用处进行捕获处理  
    });
}

// 封装put请求方法  
export function put(url, data = {}) {
    return service.put(url, data).catch((error) => {
        throw error; // 重新抛出错误，以便在调用处进行捕获处理  
    });
}

// 其他请求方法（如patch等）可以按照需要进行封装...

export default service; // 导出 service 实例
