// src/router/index.js  
import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Puzzle from '../views/Puzzle.vue'
import cookImage from '../views/cookImage.vue'
import NoteScrape from '../views/Note.vue'
import Text from '../views/Text.vue'
// import DesktopLayout from '../views/DesktopLayout.vue'

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,

        meta: { requiresAuth: false } // 这里设置 meta 字段来控制是否需要认证  

    },
    {
        path: '/note',
        name: 'Note',
        menu: { // 添加菜单信息  
            title: '笔记1',
            icon: 'el-icon-notebook'
        },
        component: NoteScrape,
        meta: { requiresAuth: true }, // 这里设置 meta 字段来控制是否需要认证  
        // children: [
        //     {
        //         path: '/note2',
        //         name: 'Note',
        //         component: NoteScrape,
        //         menu: { // 添加菜单信息  
        //             title: '笔记',
        //             icon: 'el-icon-notebook'
        //         },
        //         meta: { requiresAuth: true }, // 这里设置 meta 字段来控制是否需要认证 
        //     }
        // ]


    },
    {
        path: '/Puzzle',
        name: 'Puzzle',
        component: Puzzle,
        meta: { requiresAuth: true } // 这里设置 meta 字段来控制是否需要认证  
    }
    ,
    {
        path: '/Text',
        name: 'Text',
        component: Text,
        meta: { requiresAuth: true } // 这里设置 meta 字段来控制是否需要认证  
    }
    ,
    {
        path: '/cookImage',
        name: 'cookImage',
        component: cookImage,
        meta: { requiresAuth: true } // 这里设置 meta 字段来控制是否需要认证  

    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
// 全局前置守卫  
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('jwtToken');

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 检查路由是否需要认证  
        if (!token) {
            // 未登录，重定向到登录页面  
            next('/');
        } else {
            // 已登录，继续导航  
            next();
        }
    } else {
        // 不需要身份验证，直接导航  
        next();
    }
});

export default router