<template>
  <div class="login-container">
    <!-- 粒子背景等可以保留，如果它是登录页面的特定视觉效果 -->
    <!-- 登录表单和按钮 -->
    <div class="login-box">
      <div class="login-header">
        <h2>用户登录</h2>
      </div>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="username">用户名:</label>
          <input
            v-model="state.username"
            type="text"
            id="username"
            placeholder="请输入用户名"
          />
          <i class="fas fa-user icon"></i>
        </div>
        <div class="form-group">
          <label for="state.password">密码:</label>
          <input
            v-model="state.password"
            type="password"
            id="password"
            placeholder="请输入密码"
          />
          <i class="fas fa-lock icon"></i>
        </div>
        <Vcode :show="isShow" @success="onSuccess" @close="onClose" />

        <button type="submit" class="login-btn">登录</button>
      </form>
      <button class="register-btn" @click="navigateToRegister">注册</button>
      <!-- 添加注册按钮 -->
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

import Vcode from "vue3-puzzle-vcode";
import { post } from "../util/request"; // 引入封装的请求方法

export default {
  name: "LoginComponent",
  components: {
    Vcode,
  },
  setup(props, context) {
    // 响应式状态、方法等
    const router = useRouter();

    const showAlert = async () => {
      try {
        const result = await Swal.fire({
          title: "操作成功",
          text: "登录成功",
          icon: "success",
          confirmButtonText: "确定",
        });
        if (result.isConfirmed) {
          console.log("用户点击了确定按钮");
          //调转
          router.push("/Text");
        }
      } catch (error) {
        console.error(error);
      }
    };
    const state = reactive({
      username: "",
      password: "",
    });

    const login = () => {
      // 登录逻辑处理
      if (!state.username || !state.password) {
        Swal.fire({
          title: "提示!",
          text: "请输入用户名或密码！",
          icon: "error",
          confirmButtonText: "好的",
        });
      } else {
        isShow.value = true;
      }
    };

    const navigateToRegister = () => {
      // 跳转到注册页面的逻辑
      console.log(context, "点击");

      context.emit("flip"); // 触发自定义的flip事件
    };
    const isShow = ref(false);

    const onClose = () => {
      isShow.value = false;
    };

    const onSuccess = () => {
      onClose(); // 验证成功，需要手动关闭模态框
      const postData = { emailOrUsername: state.username, password: state.password };
      //支持用户名或者密码登录。
      post("users/login", postData)
        .then((res) => {
          if (res.success) {
            console.log("登录成功11", res);
            localStorage.setItem("jwtToken", res.data.access_token);
            showAlert("登录成功");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      state,
      login,
      navigateToRegister,
      isShow,
      onSuccess,
      onClose,
    };
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less"; // 假设你在项目的styles文件夹中定义了变量

// 定义一些颜色变量
@bg-color: #f7f7f7;
@primary-color: #3498db;
@text-color: #333;
@input-bg: #fff;

body {
  background: @bg-color;
}

.login-container {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 100vh;
  position: relative;
  height: 96vh;
}
#particles-js {
  position: absolute;
  transform: translate(-50%, -50%); /* 通过 transform 移动登录框到屏幕中心 */
  left: 50%; /* 设置 left 为 50% 以便水平居中 */
  top: 50%; /* 设置 top 为 50% 以便垂直居中 */
  width: 100%;
  height: 96vh;
}
.login-box {
  margin: 20vh auto;
  width: 400px;
  padding: 40px;
  background-color: #fcfcff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out forwards; // 应用淡入动画
}

.login-header {
  margin-bottom: 30px;
  h2 {
    color: @primary-color;
    font-weight: 500;
  }
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    &:focus {
      border-color: @primary-color;
    }
  }
  label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  .icon {
    position: absolute;
    right: 15px; // 根据需要调整此值
    top: 70%; // 垂直居中图标
    transform: translateY(-50%); // 确保图标在输入框内垂直居中
    color: #999;
  }
}
// 添加一个简单的动画效果
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.login-btn {
  width: 100%;
  padding: 10px;
  background-color: @primary-color;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: darken(@primary-color, 5%);
  }
}
// 添加注册按钮的样式
.register-btn {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px; // 与登录按钮保持一定间距
  background-color: #4caf50; // 可以自定义颜色
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: darken(#4caf50, 5%); // 鼠标悬停时颜色变深
  }
}
</style>
