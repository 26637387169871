import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // 引入路由配置  
import '@fortawesome/fontawesome-free/css/all.min.css';
import VueParticles from 'vue-particles'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import Socketio from "./util/Socket.io.ts";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import Prism from 'prismjs';
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});

const app = createApp(App)

// 使用路由  
app.use(router)
app.use(ElementPlus);

app.use(VueParticles)
app.use(VueSweetalert2)
app.use(VueMarkdownEditor);
app.use(Socketio, {
    connection: "http://localhost:3000",
    options: {
        autoConnect: false, //关闭自动连接
        // ...其它选项
    },
});

// 挂载Vue应用实例  
app.mount('#app')