<template>
  <div class="app-container">
    <div class="star-pattern">
      <div class="triangle top"></div>
      <div class="triangle bottom"></div>
      <div class="triangle left"></div>
      <div class="triangle right"></div>
      <div class="triangle topleft"></div>
      <div class="triangle topright"></div>
    </div>
    <!-- 添加的六芒星图案元素 -->

    <div class="left-panel">
      <div class="upload-button">
        <input
          type="file"
          ref="fileInput"
          @change="onFileChange"
          accept="image/*"
          hidden
        />
        <!-- 添加下拉选择框来选择平台 -->
        <select v-model="selectedPlatform" @change="resetCropper">
          <option
            v-for="(size, platform) in platformSizes"
            :key="platform"
            :value="platform"
          >
            {{ platform }}
          </option>
        </select>
        <button @click="triggerFileUpload">上传图片</button>
        <button @click="cropImage">裁剪图片</button>
        <button @click="addText">添加文字</button>

        <!-- 替换原有的导出图片按钮 -->
        <!-- 使用 Element Plus 的下拉菜单组件 -->
        <el-dropdown split-button type="primary" @click="downloadImage(1)">
          导出图片
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="downloadImage(0.7)">压缩70%画质</el-dropdown-item>
              <el-dropdown-item @click="downloadImage(0.1)">压缩50%画质</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div v-if="imageUrl">
        <div class="canvasDiv">
          <img ref="image" :src="imageUrl" alt="Source Image" />
        </div>
      </div>
      <div class="input-fields">
        <div>
          <label for="authorName">作者姓名:</label>
          <input
            type="text"
            id="authorName"
            v-model="authorName"
            placeholder="请输入作者姓名"
          />
        </div>
        <div>
          <label for="bookTitle">书名:</label>
          <input
            type="text"
            id="bookTitle"
            v-model="bookTitle"
            placeholder="请输入书名"
            @input="setBookTitle"
          />
        </div>
      </div>
    </div>

    <div class="right-panel">
      <canvas ref="canvas" width="400" height="400" hidden></canvas>
      <img :src="croppedImageDataURL" ref="nextImage" alt="暂无" hidden />
      <canvas ref="canvasRef" width="400" height="400" class="fabricCanvas"></canvas>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick, watch, computed } from "vue";
import Cropper from "cropperjs";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import { fabric } from "fabric";

import "cropperjs/dist/cropper.css";

const imageUrl = ref("");
const image = ref(null);
const canvas = ref(null);
let cropperInstance = null;
const fileInput = ref(null);
let croppedImageDataURL = ref(""); // 添加一个变量来存储裁剪后的图片DataURL
const authorName = ref("231");
const bookTitle = ref("");
const canvasRef = ref(null);
const nextImage = ref(null);

// 添加平台尺寸映射
const platformSizes = {
  起: { width: 600, height: 800 },
  创: { width: 600, height: 800 },
  // ... 其他平台尺寸 ...
};

const selectedPlatform = ref("起"); // 默认选择起点平台
const selectedSize = computed(() => platformSizes[selectedPlatform.value]);

// const setBookTitle = () => {
//   canvas.renderAll();
// };

const onFileChange = (event) => {
  const files = event.target.files || event.dataTransfer.files;
  console.log(files, "第几个图片");
  if (!files.length) return;

  createImage(files[0]);
};
const triggerFileUpload = () => {
  // 触发隐藏的input的点击事件
  fileInput.value?.click();
};
const createImage = (file) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    imageUrl.value = e.target.result;
    if (cropperInstance) {
      cropperInstance.destroy();
      cropperInstance = null;
    }
    nextTick(() => {
      initCropper();
    });
  };
  reader.readAsDataURL(file);
};

const downloadImage = async (quality) => {
  if (!croppedImageDataURL.value) {
    Swal.fire({
      title: "提示!",
      text: "请先裁剪图片再导出！",
      icon: "warn",
      confirmButtonText: "好的",
    });
    return;
  }

  // 将DataURL转换为Blob对象
  const blob = await dataURLtoBlob(croppedImageDataURL.value);

  // 使用browser-image-compression进行压缩
  const options = { maxSizeMB: 2, maxWidthOrHeight: 1920, quality: quality };
  try {
    const compressedFile = await imageCompression(blob, options);

    // 创建一个不可见的a标签来下载压缩后的图片
    const link = document.createElement("a");
    const compressedImageUrl = URL.createObjectURL(compressedFile);
    link.href = compressedImageUrl;
    link.download = "compressed_image.png"; // 设置下载图片的名字
    document.body.appendChild(link);
    link.click(); // 模拟点击下载
    document.body.removeChild(link); // 下载完成后移除a标签
    URL.revokeObjectURL(compressedImageUrl); // 释放URL对象
  } catch (error) {
    console.error("Image compression failed:", error);
  }
};

// 辅助函数：将DataURL转换为Blob对象
function dataURLtoBlob(dataURL) {
  const base64Data = dataURL.split(",")[1];
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: "image/png" });
}

const initCropper = () => {
  if (cropperInstance) {
    cropperInstance.destroy();
  }
  croppedImageDataURL.value = "";
  cropperInstance = new Cropper(image.value, {
    aspectRatio: selectedSize.value.width / selectedSize.value.height,
    viewMode: 1,
    // 其他Cropper.js配置...
    crop: function (event) {
      console.log(event.detail.x);
    },
  });
};

const cropImage = () => {
  if (!cropperInstance) return;

  const croppedCanvas = cropperInstance.getCroppedCanvas();
  const ctx = canvas.value.getContext("2d");
  const targetWidth = 400;
  const targetHeight = 400;
  const sourceWidth = croppedCanvas.width;
  const sourceHeight = croppedCanvas.height;

  const scaleX = targetWidth / sourceWidth;
  const scaleY = targetHeight / sourceHeight;
  const scale = Math.min(scaleX, scaleY);

  const destWidth = sourceWidth * scale;
  const destHeight = sourceHeight * scale;
  const xOffset = (targetWidth - destWidth) / 2;
  const yOffset = (targetHeight - destHeight) / 2;

  ctx.clearRect(0, 0, targetWidth, targetHeight);
  ctx.drawImage(croppedCanvas, xOffset, yOffset, destWidth, destHeight);
  croppedImageDataURL.value = canvas.value.toDataURL("image/png");
  //   console.log(croppedImageDataURL, "裁切图鉴");
  // 将HTML5 Canvas内容转换为Fabric图像并添加到Fabric画布上
  //   const fabricCanvas = new fabric.Canvas(fabricCanvasAll.value);
  //   const imgInstance = new fabric.Image(nextImage, {
  //     left: 100,
  //     top: 100,
  //     angle: 30,
  //     opacity: 0.85,
  //   });
  //   console.log(imgInstance, "timgInstanceext");
  //   fabricCanvas.add(imgInstance);
  //   console.log(fabricCanvas, "fabricCanvas");
};

onMounted(() => {
  const canvas = new fabric.Canvas(canvasRef.value);

  // 添加一个矩形对象
  const rect = new fabric.Rect({
    left: 50,
    top: 50,
    fill: "red",
    width: 100,
    height: 100,
  });
  canvas.add(rect);

  // 添加一个文本对象
  const text = new fabric.Textbox(`${authorName.value}\n${bookTitle.value}`, {
    left: 50,
    top: 200,
    fill: "#000",
    scaleX: 0.5,
    scaleY: 0.5,
    angle: 0,
    originX: "left",
    originY: "top",
    shadow: "3px 3px 10px rgba(0,0,0,0.5)", // 文本阴影
    fontWeight: "bold", // 字体加粗
  });
  canvas.add(text);
  text.animate("left", "+=100", {
    onChange: canvas.renderAll.bind(canvas),
    duration: 1000,
    easing: fabric.util.ease.easeOutBounce,
  });
  // 监听 textContent 的变化，并更新文本对象
  watch(bookTitle, (newVal) => {
    text.text = newVal; // 设置新的文本内容
    text.setCoords(); // 更新文本对象的坐标信息
    canvas.renderAll(); // 重新渲染画布
  });
});

onUnmounted(() => {
  if (cropperInstance) {
    cropperInstance.destroy();
    cropperInstance = null;
  }
});
</script>

<style lang="less">
.fabricCanvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.input-fields {
  margin: 10px 0;
}

.input-fields input[type="text"] {
  padding: 5px;
  width: 200px;
  margin-top: 5px;
}
.app-container {
  display: flex;
  //   justify-content: space-between;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle,
        transparent 20%,
        rgba(255, 255, 255, 0.1) 20%,
        rgba(255, 255, 255, 0.1) 80%,
        transparent 80%,
        transparent
      ),
      radial-gradient(
          circle,
          transparent 20%,
          rgba(255, 255, 255, 0.1) 20%,
          rgba(255, 255, 255, 0.1) 80%,
          transparent 80%,
          transparent
        )
        50px 50px,
      linear-gradient(
        45deg,
        rgba(255, 0, 0, 0.1) 25%,
        transparent 25%,
        transparent 75%,
        rgba(255, 0, 0, 0.1) 75%,
        rgba(255, 0, 0, 0.1)
      ),
      linear-gradient(
        45deg,
        rgba(255, 0, 0, 0.1) 25%,
        transparent 25%,
        transparent 75%,
        rgba(255, 0, 0, 0.1) 75%,
        rgba(255, 0, 0, 0.1)
      );
    background-size: 100px 100px, 100px 100px, 50px 50px, 50px 50px;
    background-position: 0 0, 50px 50px, 0 0, 25px 25px;
    z-index: -1; // 将背景图案放置在内容之下
  }
}

.left-panel {
  flex: 1;
  display: flex;
  position: relative; // For positioning the buttons
  .upload-button,
  .crop-button {
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 10px;
    // Additional styles for the crop button to position it below the upload button
    &.crop-button {
      top: 50px; // Adjust this to space the buttons vertically
    }
  }
  .image-container {
    margin-top: 100px; // Make room for the buttons at the top
    img {
      width: 100%;
      height: auto;
    }
  }
}

.right-panel {
  flex: 1;
  position: relative;
  max-width: 200px; // Adjust this to your needs
  canvas {
    width: 100%;
    height: auto;
  }
}
/* 样式可以根据需要进行自定义 */
.canvasDiv {
  width: 400px;
  height: 400px;
  width: 400px;
  height: 400px;
  margin-top: 56px;
  margin-left: 10px;
}
.upload-button {
  display: inline-block;
}

.upload-button button {
  padding: 10px 20px;
  background-color: #4caf50; /* 绿色背景 */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.upload-button button:hover {
  background-color: #45a049; /* 鼠标悬停时颜色变浅 */
}
.image-preview {
  width: 300px; /* 设置图片预览区域的宽度 */
  height: auto; /* 高度自适应 */
  border: 1px solid #ccc; /* 添加边框 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  margin-bottom: 20px; /* 添加下边距 */
}

.image-preview img {
  width: 100%; /* 图片宽度占满容器 */
  height: auto; /* 高度自适应 */
  display: block; /* 去除图片下方空白 */
}
/* 添加一个简单的六芒星图案 */
.star-pattern {
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 200px;
  height: 200px;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  position: absolute;
}

.triangle.top {
  border-bottom: 30px solid red;
  top: 0;
  left: 50px;
}

.triangle.bottom {
  border-top: 30px solid red;
  bottom: 0;
  left: 50px;
}

.triangle.left {
  border-right: 30px solid red;
  transform: rotate(180deg);
  top: 50px;
  left: 0;
}

.triangle.right {
  border-left: 30px solid red;
  top: 50px;
  right: 0;
}

.triangle.topleft {
  border-bottom: 30px solid red;
  transform: rotate(-60deg);
  transform-origin: 50px 0;
  top: 25px;
  left: 100px;
}

.triangle.topright {
  border-bottom: 30px solid red;
  transform: rotate(60deg);
  transform-origin: 50px 0;
  top: 25px;
  right: 100px;
}
</style>
