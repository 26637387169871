<template>
  <div class="login-container">
    <!-- 粒子背景等可以保留 -->
    <div class="register-box">
      <div class="register-header">
        <h2>用户注册</h2>
      </div>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="username">用户名:</label>
          <input
            v-model="state.username"
            type="text"
            id="username"
            placeholder="请输入用户名"
          />
          <i class="fas fa-user icon"></i>
          <span v-if="state.errors.username" class="error-message">{{
            state.errors.username
          }}</span>
        </div>
        <div class="form-group">
          <label for="password">密码:</label>
          <input
            v-model="state.password"
            type="password"
            id="password"
            placeholder="请输入密码"
          />
          <i class="fas fa-lock icon"></i>
          <span v-if="state.errors.password" class="error-message">{{
            state.errors.password
          }}</span>
        </div>
        <div class="form-group">
          <label for="confirmPassword">确认密码:</label>
          <input
            v-model="state.confirmPassword"
            type="password"
            id="confirmPassword"
            placeholder="请再次输入密码"
          />
          <i class="fas fa-lock icon"></i>
          <span v-if="state.errors.confirmPassword" class="error-message">{{
            state.errors.confirmPassword
          }}</span>
        </div>
        <div class="form-group">
          <label for="email">邮箱:</label>
          <input
            v-model="state.email"
            type="email"
            id="email"
            placeholder="请输入邮箱地址"
          />
          <i class="fas fa-envelope icon"></i>
          <span v-if="state.errors.email" class="error-message">{{
            state.errors.email
          }}</span>
        </div>
        <button type="submit" class="register-btn">注册</button>
      </form>
      <button class="login-btn" @click="navigateToLogin">已有账号？登录</button>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import axios from "axios";

export default {
  name: "RegisterComponent",
  setup(props, context) {
    const state = reactive({
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      errors: {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
      },
    });
    const validateForm = () => {
      state.errors = {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
      };

      if (!state.username) {
        state.errors.username = "用户名不能为空";
      }

      if (!state.password) {
        state.errors.password = "密码不能为空";
      }

      if (state.password !== state.confirmPassword) {
        state.errors.confirmPassword = "两次输入的密码不一致";
      }
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(state.email)) {
        state.errors.email = "请输入有效的电子邮件地址";
      }

      return Object.values(state.errors).every((error) => error === "");
    };

    const register = async () => {
      if (validateForm()) {
        // 注册逻辑处理，如果校验通过则继续处理注册逻辑
        console.log("验证通过");
        const response = await axios.post("/users", {
          name: state.username,
          email: state.email,
          password: state.password,
        });
        if (response.data.success) {
          alert("User created successfully!");
        } else {
          console.log(response.data.errors);
        }
        // ...
      } else {
        console.log("验证不通过", state);

        // 校验不通过，不执行注册逻辑
      }
    };
    const navigateToLogin = () => {
      // 跳转到登录页面的逻辑
      console.log(context, "点击");
      context.emit("flip");
    };

    return {
      ...state,
      state,
      register,
      navigateToLogin,
    };
  },
};
</script>
<style lang="less" scoped>
@import "~@/styles/variables.less"; // 假设你在项目的styles文件夹中定义了变量

// 定义一些颜色变量
@bg-color: #f7f7f7;
@primary-color: #3498db;
@text-color: #333;
@input-bg: #fff;

body {
  background: @bg-color;
}
.error-message {
  position: absolute;
  transform: translate(-50%, -50%); /* 通过 transform 移动登录框到屏幕中心 */
  left: 50%; /* 设置 left 为 50% 以便水平居中 */
  top: 70%; /* 设置 top 为 50% 以便垂直居中 */
  color: red;
}
.login-container {
  position: relative;
  height: 96vh;
}
#particles-js {
  position: absolute;
  transform: translate(-50%, -50%); /* 通过 transform 移动登录框到屏幕中心 */
  left: 50%; /* 设置 left 为 50% 以便水平居中 */
  top: 50%; /* 设置 top 为 50% 以便垂直居中 */
  width: 100%;
  height: 96vh;
}
.register-box {
  margin: 20vh auto;
  width: 400px;
  padding: 40px;
  background-color: #fcfcff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out forwards; // 应用淡入动画
}

.login-header {
  margin-bottom: 30px;
  h2 {
    color: @primary-color;
    font-weight: 500;
  }
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    &:focus {
      border-color: @primary-color;
    }
  }
  label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  .icon {
    position: absolute;
    right: 15px; // 根据需要调整此值
    top: 70%; // 垂直居中图标
    transform: translateY(-50%); // 确保图标在输入框内垂直居中
    color: #999;
  }
}
// 添加一个简单的动画效果
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.login-btn {
  width: 100%;
  padding: 10px;
  background-color: @primary-color;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: darken(@primary-color, 5%);
  }
}
// 添加注册按钮的样式
.register-btn {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px; // 与登录按钮保持一定间距
  background-color: #4caf50; // 可以自定义颜色
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: darken(#4caf50, 5%); // 鼠标悬停时颜色变深
  }
}
</style>
