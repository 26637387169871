<template>
  <div id="puzzle-game">
    <div id="upload-section">
      <file-upload
        ref="uploader"
        v-model:file="uploadedImage"
        :accept="accept"
        :multiple="false"
        :drag-drop="false"
        :max-file-size="5242880"
        @input-file="handleFileUpload"
        @input-filter="handleInputFilter"
        :before-upload="beforeUpload"
      >
        <template #default="{ uploading, dragActive }">
          <div v-if="!uploading && !dragActive">
            <button type="button" @click="triggerFileUpload">上传图片</button>
          </div>
          <div v-else>Uploading...</div>
        </template>
      </file-upload>
      <!-- 添加两个按钮 -->
      <button v-if="uploadedImage" @click="togglePreview" type="button">
        预览原始图片
      </button>
      <img
        v-if="showPreview && uploadedImage"
        :src="previewImageUrl"
        alt="Preview"
        class="allImg"
      />

      <button @click="shufflePieces" type="button">随机打乱</button>
      <button @click="resetPieces" type="button">还原初始</button>
    </div>

    <div id="puzzle-section" v-if="imageLoaded">
      <select v-model="difficulty" @change="generatePuzzle">
        <option value="3x3">3x3</option>
        <option value="4x4">4x4</option>
        <option value="5x5">5x5</option>
      </select>
      <draggable
        v-model="puzzlePieces"
        group="puzzle"
        @end="checkPuzzle"
        :style="divStyle"
      >
        <template #item="{ element }">
          <div
            class="puzzle-piece"
            :style="{ backgroundImage: 'url(' + element.src + ')' }"
          >
            {{ element.position }}
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import "vue-upload-component/dist/vue-upload-component.part.css";
import FileUpload from "vue-upload-component/dist/vue-upload-component.min.js";
import draggable from "vuedraggable";

export default {
  name: "Puzzle_view",
  components: {
    FileUpload,
    draggable,
  },
  setup() {
    const uploadedImage = ref(null);
    const puzzlePieces = ref([]);
    const difficulty = ref("5x5");
    const imageLoaded = ref(false);
    const accept = "image/*";
    // 添加一个新的响应式变量来控制预览图片的显示状态
    const showPreview = ref(false);
    // 添加一个计算属性来生成预览图片的URL
    const previewImageUrl = computed(() => {
      if (uploadedImage.value) {
        return URL.createObjectURL(uploadedImage.value.file);
      }
      return "";
    });
    // 添加一个新的方法来切换预览图片的显示状态
    function togglePreview() {
      showPreview.value = !showPreview.value;
    }
    onMounted(() => {
      // 初始化操作
    });
    const gridSize = computed(() => parseInt(difficulty.value.split("x")[0], 10)); // 根据难度计算网格大小
    const divStyle = computed(() => ({
      width: `${120 * gridSize.value}px`, // 假设每个碎片是100px * 网格数
      height: `${120 * gridSize.value}px`, // 假设每个碎片是100px * 网格数
    }));
    // 添加一个用于存储原始切片顺序的变量
    const originalPiecesOrder = ref([]);
    function triggerFileUpload() {
      // Trigger the file input click event programmatically
      document.querySelector("#file-upload-input").click();
    }

    function handleFileUpload(file) {
      if (file) {
        uploadedImage.value = file;

        createPuzzlePieces(file);
      }
    }
    function createPuzzlePieces(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const pieceWidth = image.width / gridSize.value;
          const pieceHeight = image.height / gridSize.value;
          canvas.width = pieceWidth;
          canvas.height = pieceWidth;

          const ctx = canvas.getContext("2d");
          puzzlePieces.value = [];

          for (let y = 0; y < gridSize.value; y++) {
            for (let x = 0; x < gridSize.value; x++) {
              ctx.clearRect(0, 0, pieceWidth, pieceHeight);
              ctx.drawImage(
                image,
                x * pieceWidth,
                y * pieceHeight,
                pieceWidth,
                pieceHeight,
                0,
                0,
                pieceWidth,
                pieceHeight
              );
              const dataUrl = canvas.toDataURL();
              puzzlePieces.value.push({
                src: dataUrl,
                position: y * gridSize.value + x,
                originalPosition: y * gridSize.value + x,
              });
            }
          }
          // 在生成切片后，存储原始顺序
          originalPiecesOrder.value = [...puzzlePieces.value];
          // 打乱切片顺序
          shufflePieces();
          imageLoaded.value = true;
        };
        image.src = imageUrl;
      };

      reader.readAsDataURL(file.file);
    }
    // 添加打乱切片的函数
    function shufflePieces() {
      for (let i = puzzlePieces.value.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [puzzlePieces.value[i], puzzlePieces.value[j]] = [
          puzzlePieces.value[j],
          puzzlePieces.value[i],
        ];
      }
    }

    // 添加还原初始切片的函数
    function resetPieces() {
      puzzlePieces.value = [...originalPiecesOrder.value];
    }
    function generatePuzzle() {
      console.log(uploadedImage.value, "当前是否有图片");
      if (uploadedImage.value) {
        console.log(puzzlePieces.value, "清楚");

        puzzlePieces.value = [];

        createPuzzlePieces(uploadedImage.value);
      }
    }

    function checkPuzzle() {
      // Implement your puzzle solving logic here
      const isSolved = puzzlePieces.value.every(
        (piece, index) => piece.originalPosition === index
      );
      if (isSolved) {
        alert("Congratulations! You solved the puzzle!");
      }
    }

    // Other event handlers can go here (beforeUpload, handleInputFilter, etc.)

    return {
      uploadedImage,
      puzzlePieces,
      difficulty,
      accept,
      imageLoaded,
      triggerFileUpload,
      handleFileUpload,
      generatePuzzle,
      checkPuzzle,
      divStyle,
      shufflePieces, // 添加打乱切片的方法到返回值中
      resetPieces, // 添加还原切片的方法到返回值中
      showPreview, // 添加新的响应式变量到返回的对象中
      previewImageUrl, // 添加计算属性到返回的对象中
      togglePreview, // 添加新的方法到返回的对象中
    };
  },
};
</script>
<style>
#puzzle-game {
  font-family: Arial, sans-serif;
  text-align: center;
}

.allImg {
  width: 400px;
}
#upload-section {
  margin-bottom: 20px;
}

.puzzle-piece {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-size: cover;
  border: 1px solid #000;
  margin: 1px;
  /* padding: 1px; */
  box-sizing: border-box;
  cursor: move;
  user-select: none;
}

button {
  padding: 10px 20px;
  font-size: 16px;
}
</style>
