<template>
  <div class="login-container">
    <!-- 添加粒子容器 -->
    <vue-particles
      color="#90CAF9"
      :particleOpacity="0.5"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="3"
      linesColor="#64B5F6"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.3"
      :linesDistance="150"
      :moveSpeed="2"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <div class="flip-container" :class="{ flipped: isFlipped }">
      <component :is="currentComponent" @flip="toggleComponent"></component>
    </div>
    <div class="beian-link">
      <a href="https://beian.miit.gov.cn" target="_blank">赣ICP备2024029910号</a>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import LoginComponent from "./LoginComponent.vue"; // 引入LoginComponent
import RegisterComponent from "./RegisterComponent.vue"; // 引入RegisterComponent

export default {
  name: "LoginView",
  components: {
    LoginComponent, // 注册LoginComponent
    RegisterComponent, // 注册RegisterComponent
  },
  setup() {
    // const state = reactive({
    //   currentComponent: "LoginComponent", // 初始状态为登录组件
    //   // ...（其他状态保持不变）...
    // });

    let isFlipped = ref(false);
    let currentComponent = ref("LoginComponent");

    const toggleComponent = () => {
      currentComponent.value =
        currentComponent.value == "LoginComponent"
          ? "RegisterComponent"
          : "LoginComponent";
      isFlipped.value = !isFlipped.value; // 切换翻转状态

      console.log("点击切换", currentComponent.value);
    };

    // ...（其他方法保持不变）...
    // currentComponent = state.currentComponent;

    return {
      currentComponent,
      isFlipped,
      toggleComponent,
      // ...（返回其他状态和方法）...
    };
  },
};
</script>
<style lang="less" scoped>
@import "~@/styles/variables.less"; // 假设你在项目的styles文件夹中定义了变量

// 定义一些颜色变量
@bg-color: #f7f7f7;
@primary-color: #3498db;
@text-color: #333;
@input-bg: #fff;

body {
  background: @bg-color;
}
.flip-container {
  perspective: 1000px; // 设置透视效果
  transition: transform 0.5s; // 设置翻转动画的过渡时间
}

.flip-container.flipped {
  transform: rotateY(360deg); // 翻转180度
}
.login-container {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 100vh;
  position: relative;
  height: 96vh;
}
#particles-js {
  position: absolute;
  transform: translate(-50%, -50%); /* 通过 transform 移动登录框到屏幕中心 */
  left: 50%; /* 设置 left 为 50% 以便水平居中 */
  top: 50%; /* 设置 top 为 50% 以便垂直居中 */
  width: 100%;
  height: 96vh;
}
.login-box {
  margin: 20vh auto;
  width: 400px;
  padding: 40px;
  background-color: #fcfcff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out forwards; // 应用淡入动画
}

.login-header {
  margin-bottom: 30px;
  h2 {
    color: @primary-color;
    font-weight: 500;
  }
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    &:focus {
      border-color: @primary-color;
    }
  }
  label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  .icon {
    position: absolute;
    right: 15px; // 根据需要调整此值
    top: 70%; // 垂直居中图标
    transform: translateY(-50%); // 确保图标在输入框内垂直居中
    color: #999;
  }
}
// 添加一个简单的动画效果
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.login-btn {
  width: 100%;
  padding: 10px;
  background-color: @primary-color;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: darken(@primary-color, 5%);
  }
}
// 添加注册按钮的样式
.register-btn {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px; // 与登录按钮保持一定间距
  background-color: #4caf50; // 可以自定义颜色
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: darken(#4caf50, 5%); // 鼠标悬停时颜色变深
  }
}
.beian-link {
  position: fixed;
  bottom: 10px; // 距离底部的位置
  left: 0;
  right: 0;
  text-align: center; // 居中显示
  font-size: 12px; // 字体大小
  color: #999; // 字体颜色
  a {
    color: #999; // 链接字体颜色
    text-decoration: none; // 去除下划线
    &:hover {
      color: #666; // 鼠标悬停颜色
    }
  }
}
</style>
